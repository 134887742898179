import { OrganizationFeatureFlag } from "types/feature_flags";
import { LocalFeatureFlag, flipper } from "utils/flipper";
import { useFeatureFlagsQuery } from "utils/queries/useFeatureFlagsQuery";

export const useFeatureFlag = (flag: LocalFeatureFlag | OrganizationFeatureFlag): boolean => {
    const { data } = useFeatureFlagsQuery();

    if (flag in data) {
        return data[flag as OrganizationFeatureFlag]!;
    }

    const isEnabled = flipper.isEnabled(flag as LocalFeatureFlag);
    return isEnabled;
};
