import { useSuspenseQuery } from "@tanstack/react-query";
import axios from "axios";

import { APIRoute } from "routes";
import { OrganizationFeatureFlags } from "types/feature_flags";
import { localStorageQueryClient, STALE_TIME_RESOURCES } from "utils/query_client";

const FEATURE_FLAGS_QUERY_KEY = "feature-flags";
export function useFeatureFlagsQuery() {
    return useSuspenseQuery({
        queryKey: [FEATURE_FLAGS_QUERY_KEY],
        queryFn: () => axios.get<OrganizationFeatureFlags>(APIRoute.FEATURE_FLAGS),
        select: data => data.data,
        staleTime: STALE_TIME_RESOURCES,
    }, localStorageQueryClient);
}
